import React from 'react'
import Projects from '../Projects/Projects'
import Title from '../Title/Title'
import webDesignData from '../../ServiceData/WebDesignData.json'

const BodyWebDesign = () => {
    return (
        <div>
            <div className="characteristic" data-aos='fade-right'>
                <Title titleName={'Characteristics'} />

                <div className="characteristic-subtitle">
                    <h3 className='subtitle-tree'>Essential Elements of Our Website.</h3>
                    <p className='text-p'>Discover the key features that make our website exceptional. Built with the user experience at the forefront, our platform delivers a smooth and engaging interaction.</p>
                </div>
                <div className="characteristic_body d-flex-row-wrap-j-center-a-center">

                    <section className="characteristic_body-ul">
                        {webDesignData.map((item, id) => {
                            return (
                                <div className='characteristic_body-ul-li d-flex-a-center'>
                                    <div className="characteristic_body-ul-li-icon">
                                        <img src={item.icon} alt="icon" />
                                    </div>

                                    <div className="characteristic_body-ul-li-txt">
                                        <h3 className='subtitle-tree'>{item.title}</h3>
                                        <p className='text-p'>{item.text}</p>
                                    </div>
                                </div>)
                        })}
                    </section>

                    <div className="characteristic_body-img">
                        <img src="Assets/WhyChoose-img.png" alt="mock-up" />
                    </div>
                </div>
            </div>


            <Projects forWhatPage={'Web Design'} />


        </div>
    )
}

export default BodyWebDesign
