import React from 'react'
import Header from '../../Components/Header/Header'
import Paralax from '../../Components/Paralax/Paralax'
import AboutUs from '../../Components/AboutUs/AboutUs'
import Service from '../../Components/ServiceJsx/Service'
import WhyChooseUs from '../../Components/WhyChooseUs/WhyChooseUs'
import Projects from '../../Components/Projects/Projects'
import Contact from '../../Components/Contact/Contact'
import Footer from '../../Components/Footer/Footer'
import TopArrow from '../../Components/Top_Arrow/TopArrow'


const Home = () => {


    return (
        <div className='home bgColor' id='Home'>
            <Header />
            <Paralax
                withSlogan={true}
                firstLine={'Digital'}
                secondLine={'Marketing'}
                thirdLine={'Company'}
            />
            <AboutUs />
            <Service />
            <WhyChooseUs />
            <Projects forWhatPage={'home'} />
            <Contact />
            <Footer />
            <TopArrow />
        </div>
    )
}

export default Home
