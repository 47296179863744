import React from 'react'
import Title from '../Title/Title'
import ServiceData from '../../ServiceData/Services.json'
import { Link } from 'react-router-dom'



const Service = () => {

    return (
        <div className="service-bg">
            <div className='service' id='Service' data-aos="fade-left" >
                <Title titleName={'Services'} />
                <div className="service_body d-flex-col-just-center">
                    <div className="service_body-subtitle">
                        <h2 className='subtitle-two'>As experts, we provide The Best Solutions and Services.</h2>
                    </div>

                    <div className="serviceLinks d-flex-row-wrap-j-center">

                        {ServiceData.map((service, id) => (

                            <Link to={`/${service.link}`} className='serviceLinks_card d-flex-col-align-center' key={id} >
                                <img src={service.icon} alt={service.name} className='serviceIcon' />

                                <h2 className='btn-h3'>{service.name}</h2>

                                <p className='text-p'>{service.text}</p>
                            </Link>
                        ))}
                    </div>

                    <div className="serviceCircles" data-aos="fade-right" data-aos-delay="1000">
                        <img src="Assets/paralax_circulos.svg" alt="circle deco" />
                    </div>
                </div>
            </div>
            <div className="my-service-bg">
                <img src="Assets/contact-bg.svg" alt="contact bg" />
            </div>
        </div>
    )
}

export default Service
