import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const Footer = ({ forWhatPage }) => {

    const [showFooterMenu, setShoeFooterMenu] = useState(true)

    useEffect(() => {
        if (forWhatPage === 'ADS' || forWhatPage === 'Digital Marketing' || forWhatPage === 'Graphic Design' || forWhatPage === 'Web Design' || forWhatPage === 'Community Manager') {
            setShoeFooterMenu(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return (
        <div className='footer d-flex-col-align-center'>
            <div className="footer_logo">
                <Link to={'/'}>
                    <img src="Assets/Logo_DigitalWave.svg" alt="Digital Wave Logo" />
                </Link>
            </div>

            {showFooterMenu &&

                <div className="footer_menu d-flex-j-center">
                    <li><a href="#About_Us">About Us</a></li>
                    <li><a href="#Service">Service</a></li>
                    <li><a href="#Projects">Projects</a></li>
                </div>

            }
            <div className="footer_sm d-flex-j-center">
                <a href="https://www.instagram.com/" target='_blanc'>
                    <img src="Assets/sm_instagram_icon.svg" alt="instagram icon" />
                </a>
                <a href="https://www.linkedin.com/" target='_blanc'>
                    <img src="Assets/sm_linkedin_icon.svg" alt="linkedin icon" />
                </a>
            </div>

        </div >
    )
}

export default Footer
